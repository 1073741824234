.home-testimonials {
  padding: 200px 0;
  background: $white;

  @media (max-width: 1080px) {
    padding-top: 40px;
  }

  .testimonials-intro-content {
    @extend .col-3;
  }

  .intro-title {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $colorAccent2;
  }

  .intro-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 169%;
    color: $colorAccent2;

    @media (max-width: 1080px) {
      font-size: 16px;
    }

    strong {
      font-weight: bold;
    }
  }

  .testimonials-featured {
    position: relative;
    background: $colorAccent1;
  }

  .testimonials-visual {
    //@extend .col-7;
    position: relative;
    width:100%;

    img {
      display: block;
      margin: 0 auto;
      max-height: 400px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: rgb(0,156,218);
      background-image: -moz-linear-gradient(left,  rgba(0,156,218,1) 0%, rgba(0,140,218,1) 100%);
      background-image: -webkit-linear-gradient(left,  rgba(0,156,218,1) 0%,rgba(0,140,218,1) 100%);
      background-image: linear-gradient(to right,  rgba(0,156,218,1) 0%,rgba(0,140,218,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009cda', endColorstr='#008cda',GradientType=1 );
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      opacity: .8;
    }

    @media (max-width: 1080px) {
      display: none;
    }
  }

  .testimonials-testimonial {
    position: absolute;
    z-index: 90;
    bottom: -87px;
    padding: 50px;
    background: $white;
    box-shadow: 0 4px 40px rgba(0, 0, 0, .1);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 380px;
      height: 325px;
      background: url('/img/site/testimonials-quote.png') no-repeat center / contain;
    }

    &.first-testimonial {
      left: 30px;
      width: calc(((100% / 12) * 7) - 90px);

      &::after {
        left: 0;
        width: 300px;
        height: 256px;
      }

      .testimonial-header {
        align-items: center;
      }

      .testimonial-title {
        margin-right: 20px;
      }

      .testimonial-author {
        flex: 1 0 auto;
        margin-right: 50px;
      }
    }

    &:last-of-type {
      right: 30px;
      @extend .col-5;

      &::after {
        right: 0;
      }

      .testimonial-header {
        flex-direction: column;
        margin-bottom: 150px;
      }

      .testimonial-content {
        flex-direction: column;
      }
    }

    @media (max-width: 1080px) {
      &,
      &.first-testimonial,
      &:last-of-type {
        position: relative;
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
        width: 100%;

        padding: 20px;
      }

      &:last-of-type {
        .testimonial-header {
          margin-bottom: 0;
        }
      }
    }
  }

  .testimonial-header {
    display: flex;
  }

  .testimonial-date {
    margin: 0;
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 169%;
    color: $colorAccent2;
  }

  .testimonial-title {
    margin: 0;
    font-family: $fontText;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 169%;
    color: $colorAccent2;
  }

  .testimonial-content {
    display: flex;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
    }
  }

  .testimonial-author {
    font-family: $fontText;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 169%;
    color: $colorAccent2;

    @media (max-width: 1080px) {
      width: 100%;
    }
  }

  .testimonial-text {
    font-family: $fontText;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 200%;
    color: $colorAccent2;

    @media (max-width: 1080px) {
      width: 100%;
    }

    strong {
      font-weight: bold;
    }
  }
}
