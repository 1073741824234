// --------------------------------------------------
// Intro 001
// --------------------------------------------------

.block-intro001 {
  background-color: white;
  max-width: 100%;

  &.grey {
    background-color: $colorAccent2;
  }

  &.dark {
    background-color: $colorAccent2;

    .intro-title {
      color: #333;
    }
  }

  .block-wrapper {
    @extend .site-max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.left {
      align-items: flex-start;

      .intro-content {
        text-align: left;
        margin: 0;
      }
    }

    &.right {
      align-items: flex-end;

      .intro-content {
        text-align: right;
        margin: 0;
      }
    }
  }

  .intro-title {
    @extend .big-title;
    max-width: 1024px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: $colorAccent2;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 30px;
  }

  // .intro-separator {
  //   display: block;
  //   height: 4px;
  //   width: 135px;
  //   margin: 35px 0;
  //   padding: 0;
  //   background-color: $colorAccent1;
  //
  //   @media (max-width: 979px) {
  //     margin: 25px 0;
  //   }
  // }

  .intro-content {
    @extend .regular-text;
    width: 100%;
    max-width: 615px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    font-size: 24px;
    line-height: 140%;
    color: rgba(#535756, .6);

    @media (max-width: 1070px) {
      width: 60%;
      font-size: 21px;
    }

    @media (max-width: 599px) {
      width: 100%;
    }

    p {
      a {
        color: $colorAccent1;
      }
    }

    ul,
    ul li {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }

  .site-btn {
    margin-top: 40px;
  }
}
