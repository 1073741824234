// --------------------------------------------------
// Title
// --------------------------------------------------
p {
  margin: 0;
}

b,
strong {
  font-weight: 500;
}

p a {
  color: $colorAccent1;
  text-decoration: underline;
}

.big-title {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 140%;
  color: $colorAccent1;

  @media (max-width: 767px) {
    font-size: 35px;
  }
}

.medium-title {
  span {
    display: inline-block;
    font-family: $fontText;
    font-family: $fontTitle;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    color: $colorAccent1;
  }
}

.sub-title {
  max-width: 600px;
  margin: 0 auto;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: rgba($colorAccent2, .8);
}

// --------------------------------------------------
// text-emphasis: dot;
// --------------------------------------------------

.regular-text {
  font-family: $fontText;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;

  strong {
    font-weight: 500;
  }

  a {
    color: $colorAccent1;
    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent2;
    }
  }
}

.pagination {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: $colorAccent2;
}

// --------------------------------------------------
// Extra
// --------------------------------------------------
.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}
