.block-jobs001 {

  .cell-wrapper {
    height: 460px;
    max-width: 370px;
    border: 1px solid transparent;
    margin: 0 15px;
    transition: border .3s ease-in-out;

    .news-item {
      width: 394px;
      max-width: 394px;
      height: 456px;

      &:hover {
        transform: none;
      }

      .item-content {
        height: auto;

        .jobs-link {
          position: absolute;
          font-size: 23px;
          color: $colorAccent1;

          display: flex;
          align-items: center;

          right: 48px;
          bottom: 36px;

          i {
            margin-top: 8px;
            margin-left: 5px;
            font-size: 18px;
            font-weight: 300;
            line-height: 18px;
          }
        }
      }
    }

    &:hover {
      border-color: $colorAccent1;
    }

    &.is-selected {
      width: 370px;
      height: 460px;
      max-width: 394px;

      .item-link {
        pointer-events: auto;
      }

      .news-item {
        filter: grayscale(0);
        width: 370px;
        height: 456px;
        max-width: 394px;

        .item-title {
          color: $colorAccent1;
        }
      }
    }
  }
}
