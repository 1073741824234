// --------------------------------------------------
// Block Split001
// --------------------------------------------------

.split001 {
  //padding: 45px 0;
  padding-left: 0;
  padding-right: 0;
  background: $light;

  &.white {
    background: white;
  }

  &.degrade {
    background: linear-gradient(180deg, #F4F3F5 0%, rgba(244, 243, 245, 0) 100%);
  }

  &.small-image {
    .split001-wrapper {
      .split001-wrapper-container {
        align-items: center;
      }
    }
  }

  &.small-image {
    .split001-wrapper {
      .split001-wrapper-container {
        .split001-image-wrapper {
          img {
            max-width: 425px;

            @media(max-width: 767px) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .split001-wrapper {
    @extend .site-max-width;

    &:nth-child(even) {
      .split001-wrapper-container {
        flex-direction: row-reverse;

        .split001-image-wrapper {
          margin-left: 0;
          margin-right: auto;
        }
      }
    }
  }

  .split001-wrapper-container {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-items: flex-start;

    // Row Alignment
    &.imageLeft {
      flex-direction: row !important;
    }

    &.imageRight {
      flex-direction: row-reverse !important;
    }

    // Text Alignment
    &.centered {
      text-align: center !important;
    }

    &.left {
      text-align: left !important;
    }

    &.right {
      text-align: right !important;
    }

    // Theme
    &.light {
      background-color: white;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: $colorAccent2;
      }
    }



    &.grey {
      background-color: $colorAccent2;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: $colorAccent2;
      }
    }

    &.dark {
      background-color: $colorAccent2;

      .split001-text-wrapper .split001-content-container .split001-heading {
        color: white;
      }
    }
  }

  .split001-text-wrapper {
    width: calc((100%/12) * 5);
    display: flex;
    flex-direction: row;
    align-items: center;

    @media(max-width: 768px) {
      width: 100%;
    }

    .split001-content-container {
      //width: 80%;
      margin: 30px auto 0;

      @media(max-width: 1024px) {
        //padding: 60px 0;
      }

      .split001-heading {
        @extend .medium-title;
        margin-top: 0;
      }

      .split001-subHeading {
        margin-bottom: 48px;
      }

      .split001-text {
        @extend .regular-text;
        //margin-bottom: 48px;
      }

      .site-btn {
        @extend .site-btn;
      }

      .sub-title {
        max-width: 100%;
      }

      ul {
        padding-left: 15px;
        list-style: none;

        li {
          @extend .sub-title;
          font-size: 18px;
          padding-left: 5px;
          margin-bottom: 22px;
          font-weight: normal;

          &:last-of-type {
            margin-bottom: 0;
          }

          &:before {
            content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: red; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
          }

          p {
          }
        }
      }

      .site-btn {
        margin-top: 65px;
      }
    }
  }

  .split001-image-wrapper {
    position: relative;
    width: calc((100%/12)*6);
    margin-left: auto;

    @media(max-width: 1024px) {
      margin-top: 65px;
    }

    @media(max-width: 768px) {
      width: 100%;
    }

    img {
      display: block;
      margin: auto;
      max-width: 100%;
    }

    .video-overlay {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($colorAccent2, .5);

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        font-size: 60px;
        transition: all ease-out .2s;
      }

      &:hover {
        i {
          transform: translate(-50%,-50%) scale(1.2);
          text-shadow: 0 0 20px rgba($colorAccent2, .5);
        }
      }
    }

    .video-embed {
      display: none;
    }

    .gallery-slides {
      .gallery-slide {
        width: 100%;

        .image {
          width: 100%;
        }
      }

      .flickity-prev-next-button {
        width: 50px;
        height: 50px;
        opacity: .75;
        transition: all ease-out .2s;

        &.previous {
          left: 20px;
        }

        &.next {
          right: 20px;
        }

        &:hover {
          transform: translateY(-50%) scale(1.2);
          opacity: 1;
          box-shadow: 0 0 20px 0 rgba($colorAccent2, .5);
        }
      }
    }
  }

  // Options
  &.full-width {
    padding: 0;
    margin: 45px 0;

    .split001-wrapper {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.space-between {
    .split001-wrapper:not(:first-child) {
      margin-top: 100px;
    }
  }

  // Text Alignment
  &.closeToThePicture {
    .split001-wrapper {
      .split001-wrapper-container {
        text-align: left;
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          text-align: right;
        }
      }
    }
  }

  &.awayFormThePicture {
    .split001-wrapper {
      .split001-wrapper-container {
        text-align: right;
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          text-align: left;
        }
      }
    }
  }

  // Row Alignment
  &.imageRight {
    .split001-wrapper {
      .split001-wrapper-container {
        flex-direction: row-reverse;

        .split001-image-wrapper {
          margin-left: 0;
          margin-right: auto;


        }

        .split001-text-wrapper {
          .split001-content-container {
            text-align: left;
            flex-direction: column;
          }
        }
      }

      &:nth-child(even) {
        .split001-wrapper-container {
          flex-direction: row;
        }
      }
    }


    // Text Alignment
    &.closeToThePicture {
      .split001-wrapper {
        .split001-wrapper-container {
          text-align: right;
        }

        &:nth-child(even) {
          .split001-wrapper-container {
            text-align: left;
          }
        }
      }
    }

    &.awayFormThePicture {
      .split001-wrapper {
        .split001-wrapper-container {
          text-align: left;
        }

        &:nth-child(even) {
          .split001-wrapper-container {
            text-align: right;
          }
        }
      }
    }
  }

  // Theme
  &.grey {
    background-color: $colorAccent2;
  }

  &.dark {
    background-color: $colorAccent2;

    .split001-text-wrapper .split001-content-container {
      .split001-heading {
        color: white;
      }
    }
  }
}
