.block-team001 {
  background: linear-gradient(269.88deg, #EFEFF0 0.12%, #FAFAFA 99.91%);

  &.grey,
  &.dark {
    .team-item {
      .item-image {
        border-color: $white;
      }
    }
  }


  .medium-title {
    max-width: 280px;
    margin-right: auto;
    margin-left: auto;
  }

  .sub-title {
    font-family: $fontText;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
  }

  .team-items {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 80px;

    &.col-five {
      .team-item {
        width: calc(100% / 5);

        @media (max-width: 1100px) {
          width: calc(100% / 3);
          padding: 20px;
        }

        @media (max-width: 899px) {
          width: calc(100% / 2);
          margin-bottom: 20px;
        }

        @media (max-width: 499px) {
          width: 100%;
        }
      }
    }
  }

  .team-item {
    width: calc(100% / 3);
    padding: 0 40px;
    margin-bottom: 60px;

    @media (max-width: 1023px) {
      padding: 20px;
    }

    @media (max-width: 899px) {
      width: calc(100% / 2);
      margin-bottom: 20px;
    }

    @media (max-width: 499px) {
      width: 100%;
    }

    .item-image {
      width: fit-content;
      padding: 8px;
      margin: 0 auto 20px;
      background: transparent;
      border: 8px solid $white;

      //border: 4px solid;
      //border-image-slice: 1;
      //border-width: 4px;
      //border-image-source: linear-gradient(269.88deg, #EFEFF0 0.12%, #FAFAFA 99.91%);
      //
      border-radius: 50%;

      div {
        display: block;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-size: cover;
        background-position: unset;
        background-repeat: no-repeat;
      }
    }

    .item-title {
      margin-top: 0;
      margin-bottom: 0;
      color: $colorAccent2;

      font-family: $fontText;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;

    }

    .item-subtitle {

      font-family: $fontText;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;

    }

    .item-text {
      margin-top: 25px;
      font-weight: 300;
      font-size: 18px;
      line-height: 140%;
      text-align: center;

      :first-child {
        margin-top: 0;
      }

      ul {
        padding-left: 0;
      }

      li {
        position: relative;
        margin-top: 15px;
        padding-top: 10px;
        list-style: none;

        &:first-of-type {
          margin-top: 0;

          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $colorAccent1;
          transform: translateX(-50%);
        }
      }
    }

    .item-socials {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        svg {
          width: 36px;
        }
      }
    }
  }
}
