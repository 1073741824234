.privacy-policy {
  .main-header {
    background-color: $white;
  }
}

.terms-wrapper {
  margin-top: 50px;
  padding: 80px 20px;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .terms-content {
    @extend .regular-text;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  p {
    @extend .regular-text;
    margin: 0 0 40px;
    font-weight: 300;
  }

  h1, h2, h3, h4, h5, h6 {
    @extend .regular-text;
    margin: 0 0 40px;
  }

  ul {
    @extend .regular-text;
    margin: 0 0 40px;
    padding: 0;
    list-style: none;

    li {
      @extend .regular-text;
      position: relative;
      padding-left: 30px;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 5px;
        width: 11px;
        height: 11px;
        border-radius: 11px;
        border: 2px solid $colorAccent1;
      }
    }
  }

  img {
    margin: 0 auto 40px;
    max-width: 100%;
  }

  a {
    @extend .regular-text;
    text-decoration: underline;
    color: $colorAccent1;
  }
}
