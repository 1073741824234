.block-mission {
  position: relative;
  padding: 0;
  margin-top: -50px;

  &.vision-blue {
    background-color: rgba($colorAccent1, .1);
  }

  .mission-logo {
    position: absolute;
    top: 20px;
    left: 20px;

    @media(max-width: 1080px) {
      position: relative;
    }
  }

  .mission-container {
    padding: 100px 0;

    @media(max-width: 1080px) {
      padding: 45px 0;
    }

    .our-mission {
      display: none;
      opacity: 0;
      min-height: 525px;
      transition: opacity .5s ease-out;

      .mission-text {
        transform: translateX(100px);
        transition: transform .5s ease-out;
      }

      .block-note {
        transform: translateX(-100px);
        transition: transform .5s ease-out;
      }

      &.is-active {
        display: block;
      }

      &.is-shown {
        opacity: 1;

        .mission-text {
          transform: translateX(0);
        }

        .block-note {
          transform: translateX(0);
        }
      }
    }
  }

  .mission-text {
    font-size: 26px;
    line-height: 169%;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: calc((100% / 12) * 2);

    @media(max-width: 1080px) {
      font-size: 16px;
      padding-bottom: 45px;
      margin: 0;
    }
  }

  .toggler-holder {
    display: flex;
    justify-content: flex-end;
    margin-right: calc(100% / 12);

    @media(max-width: 1080px) {
      margin: 0;
      justify-content: flex-start;
    }

    .toggler {
      display: flex;
      font-size: 13px;
      line-height: 22px;
      text-transform: uppercase;
      align-items: center;
      font-weight: bold;
      color: $white;
      letter-spacing: 0.1em;

      &:first-child {
        .toggler-icon {
          margin-left: 32px;
        }
      }

      &:last-child {
        flex-direction: row-reverse;

        .toggler-icon {
          margin-right: 32px;
        }
      }

      &.is-active {
        color: $colorAccent2;

        .toggler-icon {
          background: $colorAccent2;
        }
      }

      .toggler-icon {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $white;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }

  .block-note {
    .note-text {
      width: calc(((100%/12) * 5) - 30px);
      margin-bottom: 30px;

      @media(max-width: 1080px) {
        max-width: 450px;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.block-note {
  position: relative;

  @media(max-width: 1080px) {
    padding-left: 20px;
    margin-left: 20px;
    // padding-right: 20px;
  }

  .note-text {
    position: relative;
    width: calc(((100%/12) * 3) - 30px);
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 27px;
    color: $colorAccent2;

    @media(max-width: 1080px) {
      max-width: 350px;
      width: 100%;
      text-align: left;
    }

    &.left-note {
      margin-left: calc(((100% / 12)*2) - 30px);

      @media(max-width: 1080px) {
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        text-align: left;
      }
    }

    &.right-note {
      margin-right: calc(((100% / 12)*2) - 30px);
    }

    &.center-note {
      margin: auto;
    }

    &:after {
      content: '';
      position: absolute;

      top: -15px;
      left: 0;

      height: 13px;
      width: 13px;

      background: #B9C0CE;

      @media(max-width: 1080px) {
        top: -15px;
        left: -20px;
      }
    }
  }
}
