.home-services {
  position: relative;
  // padding-top: 100px;
  // padding-bottom: 50px;
  // background: $light;
  z-index: 2;

  // @media (max-width: 1080px) {
  //   padding-top: 0;
  // }
}

.home-services-visual {
  position: absolute;
  right: 0;
  top: 0;

  @media(max-width: 870px){
    position: relative;
    width: 100%;
    top: auto;
  }
}

.home-services-content {

}

.services-title {
  display: table;
  width: auto;

  font-family: $fontTitle;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;


  color: $colorAccent1;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 25px;

  @media (max-width: 1080px) {
    font-size: 28px;
  }

  @media(max-width: 870px){
    font-size: 21px;
  }

  &.has-max-width {
    max-width: 190px;
  }
}

.services-text {

  font-family: $fontTitle;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;

  color: rgba(#4F4F4F, .8);
  text-align: center;
  max-width: 685px;
  margin: 0 auto;

  strong {
    font-weight: 800;
  }

  p {
    position: sticky;
    position: -webkit-sticky;
    top: 105px;
  }
}

.home-services-subjects {
  @extend .col-7;
  position: relative;
  width: 100%;
  color: black;

  @media(max-width: 1080px){
    width: 100%;
  }

  @media(max-width: 920px){
    min-width: 100%;
  }

  @media (max-width: 1080px) {
    // padding: 25px 20px;
    bottom: 0;
  }

  .subjects-title {
    margin: 0;
    font-family: $fontTitle;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 169%;

    @media (max-width: 1080px) {
      font-size: 22px;
      line-height: 1.2;
    }
  }

  .subjects-featured {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin: 50px 0;
    padding-left: 0;

    @media (max-width: 1080px) {
      margin-top: 25px;
      margin-bottom: 25px;
      flex-direction: column;
    }

    &.has-borders {
      border-top: 1px solid rgba($white, .3);
      border-bottom: 1px solid rgba($white, .3);

      .subject-wrapper {
        border-bottom: 1px solid rgba($white, .3);
      }
    }

    &.col-half {
      justify-content: space-between;
    }

    .subject-wrapper {
      display: flex;
      align-items: flex-start;
      padding: 30px 15px;
      list-style: none;

      width: calc(100% / 2);

      flex-wrap: wrap;

      @media (max-width: 1080px) {
        align-items: center;
        width: 100%;
      }


      .subject-texts-wrapper {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        //flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media (max-width: 766px) {
          flex-direction: column;
        }

        img {
          display: block;
          max-width: 72px;
          margin-right: 24px;


          @media (max-width: 1080px) {
            margin-right: 0;
            margin-bottom: 12px;
            align-self: center;
          }
        }

        .subject-title {
          color: $colorAccent1;

          font-family: $fontText;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0px;
          text-align: left;


          @media (max-width: 1080px) {
            text-align: center;
          }
          margin-top: 0;
        }

        .subject-text {

          font-family: $fontText;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: left;


          @media (max-width: 1080px) {
            text-align: center;
          }

          &.text-center {
            text-align: center;
          }
        }
      }

      @media (max-width: 1080px) {
        flex-direction: column;
        padding: 15px 0 30px;
      }

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        a {
          color: $white;
        }
      }

      a {
        font-family: $fontText;
        font-size: 18px;
        line-height: 100%;
        color: rgba($white, .6);
        font-weight: 400;
        transition: color .3s ease-in-out;

        @media (max-width: 1080px) {
          font-size: 16px;
          line-height: 1;
          color: $white;
        }

        strong {
          font-weight: 900;
        }

        i {
          margin-left: 20px;

          @media (max-width: 1080px) {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
