.split001.is-alexis .split001-text-wrapper .split001-content-container {
  position: relative;

  .split001-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media(max-width: 1024px) {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0, 0);
    }



    max-width: 225px;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    color: $colorAccent1;
    text-align: left;

    p {
      color: $colorAccent1;
    }
  }
}
