.block-services001 {
  // @extend .site-max-width;
  // background: $light;

  .block-services001-content {
    @extend .site-max-width;
  }

  &.alternative-version {
    .medium-title {
      span {
        border-bottom: inherit;
        font-weight: normal;
        font-size: 45px;
        line-height: 140%;
        text-transform: inherit;
        color: $colorAccent1;
      }
    }
  }

  .sub-title {
    max-width: 844px;
    margin-bottom: 90px;

    @media(max-width: 1024px) {
      margin-bottom: 45px;
    }

    @media(max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .medium-title {
    margin-bottom: 32px;
  }

  .services-items {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
  }

  .services-item {
    display: flex;
    align-items: flex-start;
    width: calc((100% / 3) - ((65px * 2) / 3));
    margin-right: 65px;
    margin-bottom: 40px;


    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    @media (max-width: 1100px) {
      width: calc((100% / 2) - (65px / 2));

      &:nth-child(3n + 3) {
        margin-right: 65px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

    }

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    .item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      margin-right: 30px;
      border-radius: 50%;
      background: $white;
      min-width: 72px;

      &.has-no-bubble {
        flex: inherit;
        width: auto;
        height: auto;
        background-color: transparent;
      }

      img {
        max-width: 30px;
      }

      .bubble-container {

        .bubble {
          &--small,
          &--medium {
            border-width: 1px;
          }

          &--small {
            width: 89px;
            height: 89px;
          }

          &--medium {
            width: 103px;
            height: 103px;
          }
        }
      }
    }

    .item-content {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      align-self: center;

      .item-title {
        margin: 0;
        font-weight: normal;
        font-size: 24px;
        line-height: 140%;
        color: $colorAccent1;
        text-align: left;
      }
    }


  }
}
