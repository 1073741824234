.slider004 {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .slider004-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;

    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  .slider004-textbox {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.align-left {
      align-items: flex-start;

      .slider004-text {
        text-align: left;
      }
    }

    &.align-right {
      align-items: flex-end;

      .slider004-text {
        text-align: right;
      }
    }
  }

  .slider004-heading {
    @extend .big-title;
    max-width: 1024px;
    text-align: center;
    margin: 0;
    color: $colorAccent2;
  }

  .slider004-text {
    @extend .regular-text;

    width: 100%;
    max-width: 775px;
    text-align: center;
    margin: 0 0 60px;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }
  }

  .slider004-slider-container {
    position: relative;
    display: block;
    margin-bottom: 40px;

    @media (min-width: 992px) {
      margin-bottom: 60px;
    }
  }

  .slide-container {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: 576px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .slide-image-box {
    position: relative;

    .image, img {
      display: block;
      width: 100%;
    }

    .video-overlay {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($colorAccent2, .5);

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 60px;
        transition: all ease-out .2s;
      }

      &:hover {
        i {
          transform: translate(-50%, -50%) scale(1.2);
          text-shadow: 0 0 20px rgba($colorAccent2, .5);
        }
      }
    }

    .video-embed {
      display: none;
    }
  }

  .slide-text-box {
    padding: 15px;

    .slide-heading {
      @extend .medium-title;
      margin: 0 0 10px;
    }

    .slide-text {
      @extend .regular-text;

      p {
        margin: 0;
      }
    }

    &.light {
      background-color: white;
    }

    &.grey {
      background-color: $colorAccent2;
    }

    &.dark {
      background-color: $colorAccent2;

      .slide-heading {
        color: white;
      }
    }

    &.align-left {
      .slide-heading, .slide-text {
        text-align: left;
      }
    }

    &.align-right {
      .slide-heading, .slide-text {
        text-align: right;
      }
    }
  }

  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
    transition: all ease-out .2s;
    cursor: pointer;
    z-index: 1;

    &:hover:not(:disabled) {
      transform: translateY(-50%) scale(1.2);
      opacity: 1;
      box-shadow: 0 0 20px 0 rgba($colorAccent2, .5);
    }

    &.previous {
      left: -80px;
    }

    &.next {
      right: -80px;
    }

    &:disabled {
      opacity: .5;
    }
  }

  .flickity-page-dots {
    left: 0;
    bottom: -30px;

    .dot {
      background-color: $white;
      transition: background-color ease-out .2s;

      &.is-selected {
        background-color: $colorAccent1;
      }
    }
  }

  // Options
  &.grey {
    background-color: $colorAccent2;
  }

  &.dark {
    background-color: $colorAccent2;

    .slider004-heading, .slide-text-box .slide-heading {
      color: white;
    }
  }

  .slider004-slider {
    &.columns-5 .slide-container {
      @media (min-width: 576px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (min-width: 992px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
    &.columns-3 .slide-container {
      @media (min-width: 576px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media (min-width: 992px) {
        flex: 0 0 33.33334%;
        max-width: 33.33334%;
      }
    }

    &.columns-2 .slide-container {
      @media (min-width: 576px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

}
