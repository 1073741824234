// --------------------------------------------------
// Main Grid Components
// --------------------------------------------------
body {
  &.no-scroll {
    overflow: hidden;
    height: 100%;
  }
}

.site-container {
  padding-top: 123px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding-top: 103px;
  }

  @media (max-width: 777px) {
    padding-top: 73px;
  }
}

.site-grid {
  position: relative;
  overflow: hidden;
}

.site-block-ptpb {
  padding-top: 100px;
  padding-bottom: 100px;

  @media only screen and (max-width: 1349px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  @media only screen and (max-width: 1099px) {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.site-max-width {
  position: relative;
  width: 100%;
  max-width: calc(1192px + (138px * 2));
  margin: 0 auto;
  padding-left: 138px;
  padding-right: 138px;

  &.overflow-visible {
    overflow: visible;
  }

  @media only screen and (max-width: 1349px) {
    // padding: 75px 45px;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media only screen and (max-width: 1099px) {
    // padding: 75px 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  @media only screen and (max-width: 767px) {
    // padding: 25px 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-content {
  width: 100%;
}



















//
//
// DEPRECATED
//
//
.main-aside {

}

.has-aside .main {
  position: relative;
  width: 100%;
  padding-left: 300px;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;

  & > section {
    border-left: 1px solid $colorAccent2;
  }

  @media screen and (max-width: 1204px) {
    padding-left: 0;

    & > section {
      border-left: none;
    }
  }
}

@media screen and (max-width: 1204px) {
  .main-aside.show + .main {
    -webkit-transform: translate(300px);
    -ms-transform: translate(300px);
    transform: translate(300px);
  }
}

//col-system

.col-1 {
  width: calc(100% / 12);
}

.col-2 {
  width: calc((100% / 12) * 2);
}

.col-3 {
  width: calc((100% / 12) * 3);

  @media (max-width: 870px) {
    width: 100%;
  }
}

.col-4 {
  width: calc((100% / 12) * 4);

  @media (max-width: 870px) {
    width: 100%;
  }
}

.col-5 {
  width: calc((100% / 12) * 5);

  @media (max-width: 870px) {
    width: 100%;
  }
}

.col-6 {
  width: calc((100% / 12) * 6);

  @media (max-width: 870px) {
    width: 100%;
  }
}

.col-7 {
  width: calc((100% / 12) * 7);

  @media (max-width: 870px) {
    width: 100%;
  }
}

.col-8 {
  width: calc((100% / 12) * 8);

  @media (max-width: 870px) {
    width: 100%;
  }
}

.col-9 {
  width: calc((100% / 12) * 9);
}

.col-10 {
  width: calc((100% / 12) * 10);
}

.col-11 {
  width: calc((100% / 12) * 11);
}

.col-12 {
  width: 100%;
}




// --------------------------------------------------
// With Aside
// --------------------------------------------------
.has-aside {

}

.is-aside-active {

}

.init-grid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .col {
    width: 50%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .col-left {
    padding-right: 30px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }

  .col-right {
    padding-left: 30px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
}
