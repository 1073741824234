.block-news001,
.blog-listing {

  .news001-content {
    .medium-title {
      span   {
        color: $colorAccent1;
        border-bottom: none;
        text-transform: none;
      }
    }

    .sub-title {
      max-width: 685px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;

    }
  }

  .news-items {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    width: 100%;
    overflow: hidden;

    @media (max-width: 766px) {
      flex-wrap: wrap;
    }

    .flickity-viewport {
      width: 100%;
      padding-bottom: 20px;
      transition: height .3s;
    }

    .flickity-slider {
      display: flex;
      align-items: center;
    }

    .news-item {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
      filter: none;

      .item-content {
        .item-title {
          font-family: $fontText;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 115%;
          color: $colorAccent1;
          margin-top: 0;
          margin-bottom: 24px;
        }

        .item-text {
          margin-top: 0;
        }
      }
    }
  }

  .cell-wrapper {
    width: 100%;
    max-width: 360px;
    border-radius: 10px;
    margin: 0 15px 36px; //margin-bottom offset boxshadow
    display: flex;
    align-items: center;

    @media (max-width: 766px) {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    &.is-previous,
    &.is-selected,
    &.is-next {

      .news-item {
        box-shadow: 0 0 20px -10px rgba(0, 0, 0, .3);
      }
    }

    /*&.is-previous {

      margin-left: 30px;

      &.is-selected {
        margin-left: 15px;
      }

      &:nth-last-child {
        margin-left: 15px;
      }
    }

    &.is-first-cell {
      margin-left: 30px;

      &.is-selected {
        margin-left: 25px;
      }
    }

    &:nth-last-child(2).is-previous {
      margin-left: 15px;
    }

    &.is-next,
    &:nth-child(3) {
      margin-right: 30px;
    }*/

    &.is-selected {
      max-width: 370px;
      height: 560px;

      .item-link {
        pointer-events: auto;
      }

      .news-item {
        filter: grayscale(0);
        max-width: 370px;
        height: 560px;

        .item-title {
          color: $colorAccent1;
        }
      }
    }
  }

  .is-next + .cell-wrapper {
    margin-left: 30px;
  }

  .item-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .news-item {
    position: relative;
    width: 100%;
    //max-width: 360px;
    height: 400px;
    border-radius: 10px;
    background: $white;
    filter: grayscale(1);
    transition: all .5s ease-in-out;

    &:hover {
      transform: scale(1.03);
    }

    .item-visual,
    .item-content {
      height: 50%;
    }

    .item-content {
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .item-visual {
      width: 100%;
      height: 50%;
      min-height: 50%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .item-title {
      margin: 0;
      font-family: $fontTitle;
      font-weight: normal;
      font-size: 24px;
      color: $colorAccent2;
      margin-bottom: 35px;
    }

    .item-text {
      margin-top: 35px;
    }
  }
}
