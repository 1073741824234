// --------------------------------------------------
// Block slider006
// --------------------------------------------------

.slider006 {
  text-align: center;
  // padding-top: 60px;
  // padding-bottom: 60px;
  // background: linear-gradient(269.78deg, #F4F3F5 0.12%, #FFFFFF 99.91%);
  background: $colorAccent2;

  // @media (min-width: 992px) {
  //   padding-top: 100px;
  //   padding-bottom: 100px;
  // }

  .slider006-container {
    @extend .site-max-width;
  }

  .slider006-heading {
    @extend .big-title;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 140%;
    text-align: center;
    color: $light
  }

  .slider006-text {
    @extend .regular-text;
    width: 100%;
    max-width: 554px;
    padding: 0 20px;
    text-align: center;
    color: $light;
    margin: 0 auto 50px;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }
  }

  .slider006-slider {
    position: relative;
    text-align: center;
    display: flex;
    margin-top: 90px;

    .simplebar-content-wrapper {
      padding-top: 46px;
    }

    .simplebar-track {
      pointer-events: auto;
      cursor: pointer;
    }

    .simplebar-content {
      display: flex;
    }

    .card-texts-wrapper {
      align-self: center;
      text-align: left;

      .value-title {
        margin-bottom: 10px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        color: $light;
      }

      .value-desc {
        @extend .regular-text;
        width: 100%;
        margin: 0;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        color: $light;
      }

      .value-args {
        display: flex;
        justify-content: space-between;

        @media (max-width: 599px) {
          flex-wrap: wrap;
        }
      }
    }

    .value-card {
      display: flex;
      flex-direction: column;
      max-width: 330px;
      margin-right: 120px;
      flex: 1 0 100%;

      padding-bottom: 40px;
      transition: opacity .4s ease;

      @media all and (max-width: 1024px) {
        flex-direction: column;
        padding-bottom: 80px;
      }

      &.is-selected {
        position: relative;
        opacity: 1;
        transition: opacity .4s ease;
      }

      // Options
      &.grey {
        background-color: $colorAccent2;
      }

      &.dark {
        background-color: $colorAccent2;

        .value-title {
          color: white;
        }
      }

      &.left {
        .value-title, .value-desc {
          text-align: left;
        }
      }

      &.right {
        .value-title, .value-desc {
          text-align: right;
        }
      }
    }
  }

  // Options
  &.grey {
    background-color: $colorAccent2;
  }
  &.dark {
    background-color: $colorAccent2;

    .slider006-heading {
      color: white;
    }
  }
}
