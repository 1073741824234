// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn,
[type="submit"] {
  display: inline-block;
  padding: 10px 35px;
  border-radius: 25px;
  border: 1px solid $colorAccent1;

  font-family: $fontTitle;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;

  color: $colorAccent1;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  -webkit-appearance: none;

  &.is-dark {
    border: 1px solid $colorAccent2;
    color: $colorAccent2;
  }

  &.is-red {
    color: $white;
    background: $colorAccent1;
  }

  &.is-transparent {
    color: #535756;
    background: $white;
    border: 1px solid $white;
  }

  &.is-white {
    border: 1px solid $white;
    color: $white;
  }

  @media(min-width: 1100px) {
    &:hover {
      color: $white;
      background: $colorAccent1;

      &.is-red {
        color: $colorAccent1;
        background: $white;
      }

      &.is-dark {
        color: $white;
        background: $colorAccent2;
      }

      &.is-white {
        color: $colorAccent1;
        background: $white;
      }

      &.is-transparent {
        color: $white;
        background: #535756;
        border: 1px solid #535756;
      }
    }
  }


}

.site-link-btn {
  font-family: $fontText;
  font-size: 16px;
  line-height: 19px;
  color: $colorAccent1;
}
