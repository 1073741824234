// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  z-index: 50;
  background: $white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .15);

  .app-blog & {
    background: $white;
  }

  &.is-sticky {
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);

    .header-logo {
      img,
      svg {
        height: 80px;

        @media (max-width: 1024px) {
          height: 60px;
        }

        @media (max-width: 767px) {
          height: 30px;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    padding: 40px 0;
  }

  @media (max-width: 1299px) {
    padding: 30px 0;
  }

  @media (max-width: 1100px) {
    padding: 20px 0;
  }

  .header-container {
    @extend .site-max-width;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo {
    img,
    svg {
      height: 80px;

      @media (max-width: 1024px) {
        height: 60px;
      }

      @media (max-width: 767px) {
        height: 30px;
      }
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    .header-link {
      position: relative;
      margin: 0 0 0 20px;
      font-family: $fontText;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0px;

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: $colorAccent2;

      transition: all 0.35s ease;

      @media (max-width: 1024px) {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background: $colorAccent1;
        transform-origin: center;
        opacity: 0;
        transition: opacity 0.35s ease;
      }

      &:first-of-type {
        margin: 0;
      }

      &.is-active,
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    .site-btn {
      margin: 0 0 0 20px;

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .mobile-menu-trigger {
      display: none;
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;

      @media (max-width: 1024px) {
        display: block;
      }

      &.is-open {
        .menu-trigger-burger {
          span {
            width: 0;

            &:nth-child(1) {
              transition-delay: 0s;
            }

            &:nth-child(2) {
              transition-delay: .125s;
            }

            &:nth-child(3) {
              transition-delay: .25s;
            }
          }
        }
        .menu-trigger-close {
          span {
            &:nth-child(1) {
              height: 80%;
              transition-delay: .625s;
            }
          }
          span {
            &:nth-child(2) {
              width: 80%;
              transition-delay: .375s;
            }
          }
        }
      }

      span {
        display: block;
        border-radius: 2px;
        background: $colorAccent2;
      }

      .menu-trigger-burger {
        position: absolute;
        height: 100%;
        width: 100%;

        span {
          position: relative;
          width: 30px;
          height: 4px;
          margin: 5px 0;

          &:nth-child(1) {
            transition-delay: .5s;
          }

          &:nth-child(2) {
            transition-delay: .625s;
          }

          &:nth-child(3) {
            transition-delay: .75s;
          }
        }
      }

      .menu-trigger-close {
        position: absolute;
        height: 100%;
        width: 100%;
        transform: rotate(45deg);

        span {
          background: $colorAccent1;

          &:nth-child(1) {
            position: absolute;
            top: 10%;
            left: 48px;
            height: 0;
            width: 4px;
            transition-delay: 0s;
          }

          &:nth-child(2) {
            position: absolute;
            left: 10%;
            top: 48px;
            width: 0;
            height: 4px;
            transition-delay: .25s;
          }
        }
      }
    }
  }
}

@keyframes ison {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.50;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }

}
