.home-presse {
  // background: linear-gradient(to bottom, $white 0%,$white 70%,$white 70%,$white 30%,$white 100%);;

  .home-presse-wrapper {
    @media (max-width: 1080px) {
      flex-direction: column-reverse;
    }
  }

  .home-presse-column {
    @extend .col-6;

    .column-visual{
      img {
        display: block;
        width: 100%;
      }
    }

    &:first-of-type {
      padding-top: 100px;

      @media (max-width: 1080px) {
        padding-top: 40px;
      }
    }

    &:last-of-type {
      padding-left: calc(100% / 12);

      @media (max-width: 1080px) {
        width: 100%;
        padding: 0;
      }
    }

    .content-box {

      padding: 20px;

      @media (max-width: 599px) {
        padding: 20px;
      }
      .box-event-sale {
        @media (max-width: 599px) {
          width: 100%;
        }
      }

      .box-event-infos {
        // align-items: start;
        max-width: 300px;

        img {
          max-width: 40px;
        }
      }


      .corner-icon {
        display: block;
        position: absolute;
        bottom: 20px;
        right: 20px;
        max-width: 100px;

        @media (max-width: 599px) {
          display: none;
        }
      }
    }
  }

  .content-box {
    position: relative;
    width: calc((100% / 5) * 4);
    margin-top: 100px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);

    @media (max-width: 1080px) {
      width: 100%;
      margin-top: 40px;
    }

    .corner-icon {
      z-index: 0;
    }
  }

  .box-event-infos {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .icon-check {
      margin-right: 20px;
    }

    .infos-text p {
      margin: 0;
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      color: $colorAccent1;

      strong {
        font-weight: bold;
      }
    }
  }

  .box-event-sale p {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-top: 60px;
    margin-bottom: 20px;
    padding: 5px 20px;
    border-radius: 25px;
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: $white;

    strong {
      font-weight: bold;
    }
  }
}

.home-presse-wrapper {
  display: flex;
  padding: 100px 0;
  padding-left: 0;

  @media (max-width: 1080px) {
    padding-top: 40px;
  }

  @media (max-width: 599px) {
    padding: 20px;
    padding-top: 40px;
  }
}

.presse-content {
  width: calc((100% / 5) * 3);
  //margin-right: auto;

  @media (max-width: 1080px) {
    width: 100%;
  }

  .presse-title {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $colorAccent2;
  }

  .presse-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 169%;
    color: $colorAccent2;

    @media (max-width: 1080px) {
      font-size: 16px;
    }

    strong {
      font-weight: bold;
    }
  }
}
