// --------------------------------------------------
// Block Slider002
// --------------------------------------------------

.slider002 {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .slider002-heading {
    @extend .big-title;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
    color: $colorAccent2;
  }

  .slider002-text {
    @extend .regular-text;
    width: 100%;
    max-width: 775px;
    padding: 0 20px;
    text-align: center;
    margin: 0 auto 60px;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }
  }

  .carousel-items {
    opacity: 0;
    transition: opacity .3s ease;

    &.is-selected {
      opacity: 1;
      transition: opacity .3s ease;
    }

    .image, img {
      max-width: 800px;
    }
  }

  .flickity-page-dots {
    bottom: -40px;

    .dot {
      background-color: $white;
      transition: background-color ease-out .2s;

      &.is-selected {
        background-color: $colorAccent1;
      }
    }
  }

  // Options
  &.grey {
    // background: #F4F3F5;

    .flickity-page-dots .dot {
      background: $white;

      &.is-selected {
        background: $colorAccent1;
      }
    }
  }

  &.dark {
    // background-color: $colorAccent2;

    .slider002-heading {
      color: white;
    }
  }
}
