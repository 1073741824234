/******
BUBBLE ANIMATION
*******/

.bubble-container {
  position: relative;

  > * {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }

  &.is-red {

    .bubble {
      border-color: $colorAccent1;

      &--full {
        border: none;
        background: $colorAccent1;
      }
    }
  }
}

.bubble {
  opacity: 0;
  border-radius: 50%;
  border-style: solid;
  border-color: $white;

  &.is-visible {
    border-width: 2px;
  }

  &--larger {
    border-width: 2px;
    height: 214px;
    width: 214px;
  }
  &--large {
    border-width: 3px;
    height: 170px;
    width: 170px;
  }
  &--medium {
    border-width: 3px;
    height: 131px;
    width: 131px;
  }
  &--small {
    border-width: 6px;
    height: 103px;
    width: 104px;
  }

  &--full {
    height: 72px;
    width: 72px;
    background: $white;
    border: none;
    transform-origin: top left;
  }
}

.bubble {
  &--full { animation: bubbleFull 3s ease-in-out infinite; }
  &--small { animation: bubbleSmall 3s ease-in-out infinite; animation-delay: .2s }
  &--medium { animation: bubbleMedium 3s ease-in-out infinite; animation-delay: .2s }
  &--large { animation: bubbleLarge 3s ease-in-out infinite; animation-delay: .4s }
  &--larger { animation: bubbleLarger 3s ease-in-out infinite;  animation-delay: .6s }
}

@keyframes bubbleFull {
  0% { transform: scale(.75) translate(-50%, -50%); opacity: 0; }
  50% { transform: scale(1) translate(-50%, -50%);  opacity: 1; }
  100% { transform: scale(.75) translate(-50%, -50%); opacity: 0; }
}

@keyframes bubbleSmall {
  0% { opacity: 0; }
  25% { opacity: .45; }
  50% { opacity: 1; }
  60% { opacity: .45; }
  85% { opacity: 0; }
}
@keyframes bubbleMedium {
  0% { opacity: 0; }
  25% { opacity: .45; }
  50% { opacity: .8; }
  65% { opacity: .45; }
  90% { opacity: 0; }
}
@keyframes bubbleLarge {
  0% { opacity: 0; }
  25% { opacity: .3; }
  50% { opacity: .6; }
  70% { opacity: .3; }
  95% { opacity: 0; }
}

@keyframes bubbleLarger {
  0% { opacity: 0; }
  25% { opacity: .2; }
  50% { opacity: .4; }
  75% { opacity: .2; }
  100% { opacity: 0; }
}


/**********
 LINE ANIMATIONS
***********/

.steps-waves {
  &:nth-child(4) {
    .dotted-line--path {
      animation-delay: 1.5s;
    }
  }
}

.dotted-line {
  fill: none;
  stroke-linejoin: round;
  stroke-linecap: round;

  &--path {
    stroke: $colorAccent1;
    animation: lineAnimation 4s ease-in-out infinite;
    stroke-dasharray: 349;
    stroke-dashoffset: 349;
    stroke-width: 2;
  }

  &--dash {
    stroke: $white;
    stroke-dasharray: 6, 10;
    stroke-width: 3;
  }

}

@keyframes lineAnimation {
  0% {
    stroke-dashoffset: 349;
  }
  50% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -349;
  }
}
