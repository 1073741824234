.blog-cats-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 75px 0 0;
  text-align: center;

  .blog-cats {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;

    @media (max-width: 766px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .blog-cat {
    position: relative;
    z-index: 5;
    display: inline-block;
    min-width: 270px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;

    font-family: $fontTitle;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: center;

    color: $colorAccent1;
    border-radius: 25px;
    border: 1px solid $colorAccent1;
    transition: all .6s ease;

    &:hover {
      border-color: $colorAccent1;
      color: $colorAccent1;
    }

    &.is-active {
      font-weight: bold;
      color: $light;
      border-color: $colorAccent1;
      background: $colorAccent1;
    }

    @media (max-width: 599px) {
      min-width: 100px;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      font-size: 14px;
    }
  }
}

.blog-listing-wrapper {
  padding: 70px 0;

  &.grey {
    background: #EBEBEB;

    .big-title span {
      background: #EBEBEB;
    }
  }

  @media(max-width: 900px) {
    padding: 40px 20px;
  }

  &.side-container {
    padding: 0;
    margin: 0;

    .blog-listing {
      flex-direction: column;
    }

    .blog-item {
      width: 100%;
      margin: 0 0 40px;
    }
  }
}

.blog-listing-container {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .big-title {
    margin: 0 0 80px;

    @media(max-width: 900px) {
      margin: 0 0 40px;
    }
  }

  .site-btn {
    margin-top: 40px;

    @media(max-width: 900px) {
      margin-top: 20px;
    }
  }
}

.blog-listing {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  .blog-item {
    position: relative;
    width: calc(33.33334% - (140px / 3));
    margin-right: 70px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    transition: all .6s ease;

    &:nth-child(3n+3) {
      margin-right: 0;
    }

    @media(max-width: 1024px) {
      width: calc(50% - 35px);

      &:nth-child(3n+3) {
        margin-right: 70px;
      }

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }

    @media(max-width: 650px) {
      width: 100%;
      margin-right: 0;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    &:hover {
      transform: scale(1.2);

      .blog-item-category i {
        color: $colorAccent1;
      }

      .blog-item-title {
        color: $colorAccent1;
      }
    }

    .news-item {
      height: auto;

      //.item-visual {
      //  height: 215px;
      //}
    }
  }

  .image {
    width: 100%;
    max-height: 310px;

    transform: scale(1);
    transition: all 0.95s ease;
  }

  .blog-item-infos {
    position: relative;
    padding: 40px 50px;
    background-color: $colorAccent2;
    border-top: 1px solid white;
    color: white;
    font-family: $fontTitle;

    @media(max-width: 650px) {
      padding: 40px 20px;
    }

    i.icon-plus {
      position: absolute;
      right: 30px;
      top: -30px;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background-color: $colorAccent1;
      color: white;
      font-size: 25px;
      line-height: 60px;
      text-align: center;
      padding-left: 1px;

      transform: rotate(0);
      transition: all 0.35s ease;

      @media(max-width: 650px) {
        right: 20px;
        top: -20px;
        width: 40px;
        height: 40px;
        border-radius: 60px;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        padding-left: 0px;
      }
    }
  }

  .blog-item-category {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: $white;

    i {
      color: $colorAccent2;
      font-size: 28px;
      transition: all .6s ease;
    }
  }

  .blog-item-date {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
    color: rgba($colorAccent2, .8);
  }

  .blog-item-title {
    margin: 0;

    font-family: $fontText;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;

    color: $colorAccent1;
    transition: all .6s ease;
  }

  .blog-item-author,
  .blog-item-descriptive {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .2px;
    font-weight: 400;
    margin: 0;
  }
}
