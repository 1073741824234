.block-form001 {
  position: relative;
  background: linear-gradient(269.88deg, #EFEFF0 0.12%, #FAFAFA 99.91%);

  .contact-informations {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-top: 50px;
    font-family: $fontText;

    li {
      display: flex;
      margin-bottom: 26px;

      svg {
        margin-right: 10px;
      }

      p {
        display: block;
        color: #646566;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0px;
        text-align: left;
        width: 100%;
      }
    }
  }

  .bubble-container {
    position: absolute;
    opacity: .04  ;

    &:nth-of-type(1) {
      left: 0;
      bottom: 32%;
      transform: scale(2);
    }
    &:nth-of-type(2) {
      left: 15%;
      bottom: 7%;
      transform: scale(1.1);
    }
    &:nth-of-type(3) {
      left: 17%;
      bottom: 50%;
      transform: scale(0.5);
    }
    &:nth-of-type(4) {
      left: 38%;
      bottom: 38%;
      transform: scale(0.75);
    }
  }

  .form-wrapper {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .form-left-half {
    width: 100%;
    max-width: 450px;

    .big-title {
      margin: 0 0 15px;
      line-height: 100%;
    }

    .sub-title {
      font-family: $fontText;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;

      &.map-title {
        font-family: $fontText;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: left;
        color: #414141;
      }

    }
  }

  .form-right-half {
    width: 100%;
    margin-left: 70px;

    @media (max-width: 767px) {
      margin: 30px 0 0;
    }
  }
}

.contact {
  .block-form001 {
    background: $white;
    .site-max-width {
      padding-left: 110px;
      padding-right: 80px;

      @media only screen and (max-width: 1349px) {
        // padding: 75px 45px;
        padding-left: 45px;
        padding-right: 45px;
      }

      @media only screen and (max-width: 1099px) {
        // padding: 75px 25px;
        padding-left: 25px;
        padding-right: 25px;
      }

      @media only screen and (max-width: 767px) {
        // padding: 25px 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
