.block-steps002 {

  &.white {
    background-color: $white;
  }

  &.grey {
    background: $light;
  }

  .block-steps002-content {
    .site-btn {
      display: block;
      margin: 0 auto;
      max-width: 255px;

      margin-top: 60px;

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }


  .steps-items {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 50px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .steps-item {
    width: calc(100% / 3);
    max-width: 370px;
    z-index: 2;

    @media (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      margin: 0 auto 20px;
      border-radius: 50%;
      background: $colorAccent1;

      img {
        max-width: 40px;
      }

      &.has-img {
        background: $white;
        width: 150px;
        height: 150px;
        box-shadow: 0px 0px 15px rgba(black, .1);

      }
    }

    .item-title {
      margin: 0 0 20px;
      font-size: 24px;
      font-weight: normal;
      line-height: 140%;
      color: $colorAccent1;
      text-align: center;
    }

    .item-text {
      color: rgba(#535756, .8)
    }

    .item-btn {
      display: block;
      margin-top: 30px;
      width: 100%;
      text-align: center;
      font-weight: normal;
      color: #535756;
    }
  }
}
