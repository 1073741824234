// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitle: 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTitleSerif: 'Manrope', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontText: 'Manrope', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTextLight: 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #A4D65E;
$colorAccent2: #535756;

// Extras
$light: #F4F3F5;
$black: #414141;
$white : #fff;
$grey: #646566;
$grey2: #4F4F4F;
