.footer-newsletters {
  padding: 235px 0;

  @media (max-width: 1080px) {
    padding: 20px;
  }

  &.is-white {
    background: $white url("/img/site/newsletters-background.png") no-repeat left center / auto 100%;
  }

  .newsletters-content {
    width: 100%;
    max-width: 530px;
    margin: 0 auto;
    text-align: center;
    background-color: $white;
    padding: 20px;
  }

  .newsletters-title {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #5D656E;
  }

  .newsletters-text {
    font-family: $fontText;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 169%;
    text-align: center;
    color: #162D5A;

    strong {
      font-weight: bold;
    }

    @media (max-width: 1080px) {
      font-size: 16px;
    }
  }

  .newsletters-arguments {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 36px auto 50px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .newsletters-argument {
      display: flex;
      align-items: center;
      margin-right: 20px;

      @media (max-width: 767px) {
        margin: 0 0 20px;
      }

      img {
        display: block;
        max-width: 25px;
      }

      &:last-of-type {
        margin-right: 0;

        @media (max-width: 767px) {
          margin: 0;
        }
      }
    }

    .argument-text {
      margin: 0 0 0 20px;
      font-family: $fontText;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      color: #162D5A;

      @media (max-width: 1080px) {
        font-size: 14px;
      }
    }
  }
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 900;
  cursor: pointer;

  &.is-active {
    display: block;
  }
}

.newsletter-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999;
  display: none;
  width: 100%;
  max-width: 1145px;
  padding-top: 100px;
  padding-bottom: 100px;
  transform: translate(-51%, -49%);
  background: $white url('/img/site/newsletters-modal-background.png') no-repeat -170px 30% / 52% auto;

  &.is-active {
    display: flex;
  }

  @media (max-width: 799px) {
    flex-direction: column;
    height: 100%;
    padding: 50px 20px;
    background: $white url('/img/site/newsletters-modal-background.png') no-repeat -150px -200px / contain;
  }

  .newsletter-modal-close {
    position: absolute;
    top: 36px;
    right: 36px;
    display: inline-block;
    border: none;
    color: #c4c4c4;
    background: none;
    outline: none;
    cursor: pointer;

    @media (max-width: 799px) {
      top: 20px;
      right: 20px;
    }
  }

  .newsletter-modal-col {
    width: calc((100% / 10) * 5);

    @media (max-width: 799px) {
      width: 100%;
    }

    &:first-of-type {
      padding-left: calc(100% / 10);

      @media (max-width: 799px) {
        margin-bottom: 50px;
        padding-left: 0;
      }
    }

    &:last-of-type {
      padding-right: calc(100% / 10);

      @media (max-width: 799px) {
        padding-right: 0;
      }
    }
  }

  .modal-col-title {
    width: calc((100% / 5) * 3);
    margin: 0 0 20px 0;
    font-family: $fontText;
    font-style: normal;
    font-weight: bold;
    font-size: 12.8px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $colorAccent2;

    @media (max-width: 799px) {
      width: 100%;
    }
  }

  .modal-col-text {
    width: calc((100% / 5) * 4);
    font-family: $fontText;
    font-style: normal;
    font-size: 26px;
    line-height: 169%;
    letter-spacing: 0;
    color: $colorAccent2;

    @media (max-width: 799px) {
      width: 100%;
      max-width: 320px;
    }
  }

  .site-btn {
    margin-top: 50px;

    i {
      margin-left: 10px;
      color: $white;
    }
  }
}
