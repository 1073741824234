

//Footer
.site-footer {
  background-color: $colorAccent2;
  padding: 60px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    padding: 40px 0;
  }

  .footer-container {
    @extend .site-max-width;

    width: 100%;
    margin: 0 auto 35px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .footer-logo {
    height: 80px;
  }

  .footer-column {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin: 15px 0;
      flex-direction: column;
    }
  }

  .footer-text {
    font-family: $fontTitle;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: .5px;
    color: white;
    margin: 0 0 20px;
  }

  .footer-newsletter-form {
    display: flex;
    margin-bottom: 20px;

    input[type=email] {
      flex-grow: 1;
      padding: 10px 15px;
      border: none;
      outline: 0;
      line-height: 24px;
    }

    button[type=submit] {
      padding: 10px 15px;
      border: none;
      outline: 0;
      font-weight: 700;
      color: white;
      line-height: 24px;
      background-color: $colorAccent1;
    }
  }

  .nav-link {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    font-family: $fontTitle;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;

    text-transform: uppercase;
    color: $white;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
      height: 1px;
      background: $white;
      transform-origin: center;
      transform: scaleX(0);
      transition: all .4s ease;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  .social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid $colorAccent1;
    text-align: center;
    color: $white;
    background: $colorAccent1;
    transition: all .4s ease;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      background: $colorAccent2;
    }

    i {
      font-size: 16px;
    }
  }

  .footer-bottom {
    width: 100%;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    border-top: 1px solid $white;
    text-align: center;
    font-family: $fontText;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: $white;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-legal {
    margin-right: 20px;

    @media (max-width: 1023px) {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .footer-rights {
    position: relative;
    transition: color ease-out .2s;
    margin-right: 20px;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }

    @media (max-width: 1023px) {
      padding-right: 0;
      margin-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -4px;
      width: 100%;
      height: 1px;
      background: $white;
      transform-origin: center;
      transform: scaleX(0);
      transition: all .4s ease;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
}
