// --------------------------------------------------
// Block Slider003
// --------------------------------------------------

.slider003 {

  &.grey {
    background: $light;
  }

  .slider003-textbox {
    .slider-heading {
      color: $colorAccent1;
      margin-bottom: 15px;
      font-size: 45px;
      line-height: 140%;
    }

    .slider-text {
      font-weight: 300;
      font-size: 24px;
      line-height: 140%;
    }
  }

  .slider003-rows {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    //flex-wrap: wrap;
    align-items: stretch;
    height: 257px;
    margin-bottom: 10px;

    .flickity-viewport {
      width: 100%;
    }

    .flickity-prev-next-button {
      width: 50px;
      height: 50px;
      transition: all ease-out .2s;
      cursor: pointer;
      z-index: 1;
      background-color: transparent;

      &.previous {
        left: -80px;
      }

      &.next {
        right: -80px;
      }

      &:hover:not(:disabled) {
        transform: translateY(-50%) scale(1.2);
        opacity: 1;
        background-color: transparent;
      }

      &:disabled {
        opacity: .1;
      }
    }

    .flickity-page-dots {
      left: 50%;
      bottom: -35px;
      width: auto;

      transform: translateX(-50%);

      .dot {
        background-color: #ACB5B6;
        transition: background-color ease-out .2s;

        &.is-selected {
          background-color: transparent;
          border: 1px solid #ACB5B6;
        }
      }
    }
  }

  .slider003-slider {
    width: 100%;

    .slide-container {
      width: 474px;
      height: 257px;

      .slide-image-box {
        margin: 0 10px;

        img {
          margin: 0;
          display: block;
          width: 474px;
          max-width: 100%;
        }
      }
    }
  }
}
