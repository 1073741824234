
.overlay {
  position: absolute;
  display: none;
  background: rgba(#333, .6);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  &.is-active {
    display: block;
  }
}
.block-grid-modal {
  background: $light;
  position: relative;

  .modal-wrapper {
    position: relative;
    display: none;
    padding: 70px 80px;
    border-radius: 4px;
    z-index: 101;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 610px;
    transform: translate(-50%, -50%);
    background-color: $white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    @media(max-width: 801px) {
      max-width: 600px;
      padding: 60px 20px;
    }

    @media(max-width: 601px) {
      max-width: 400px;
      max-height: 100%;
    }

    @media(max-width: 400px) {
      max-width: 100%;
    }

    &.is-active {
      display: block;
    }

    .item-content {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;

      .item-text {
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        color: #535756;
        margin-bottom: 20px;

        p {
          margin: 0;
        }
      }

      .item-link {
        align-self: flex-end;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        color: $colorAccent2;

        i {
          font-size: 12px;
        }
      }

      .item-title {
        margin: 0 0 10px;
        font-size: 24px;
        line-height: 140%;
        font-weight: normal;
        color: $colorAccent1;
        text-align: left;
      }
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 28px;
    }

    .item-icon {
      position: absolute;
      top: -31px;
      left: -31px;
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      margin-right: 30px;
      border-radius: 50%;
      background: $white;

      @media(max-width: 400px) {
        left: 50%;
        transform: translateX(-50%);
      }

      img {
        display: block;
        max-width: 30px;
        background-color: white;
      }

      .bubble-container {

        .bubble {
          &--small,
          &--medium {
            border-width: 1px;
          }

          &--small {
            width: 89px;
            height: 89px;
          }

          &--medium {
            width: 103px;
            height: 103px;
          }
        }
      }
    }

    .item-sub-content-wrapper {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-wrap: wrap;



      .item-sub-content {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: calc(100% / 2 - 10px);
        margin-bottom: 20px;

        @media(max-width: 801px) {
          width: 100%;
        }

        &:nth-child(2n+1) {
          margin-right: 20px;
        }

        &:first-child {
          margin-bottom: 20px;
        }

        img {
          display: block;
          margin-right: 12px;
        }

        .sub-texts {
          h4 {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: normal;
            font-size: 24px;
            line-height: 140%;
            color: $colorAccent2;
          }

          p {
            font-weight: 300;
            font-size: 18px;
            line-height: 140%;
            margin: 0;
          }
        }
      }
    }
  }

  .medium-title {
    margin-bottom: 32px;
  }

  .services-items {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 50px;


  }

  .services-item {
    display: flex;
    align-items: flex-start;
    width: calc((100% / 3) - ((30px * 2) / 3));
    margin-right: 30px;
    margin-bottom: 30px;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    @media (max-width: 1100px) {
      width: calc((100% / 2) - (65px / 2));

      &:nth-child(3n + 3) {
        margin-right: 65px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

    }

    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      flex-direction: column;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

    }

    .item-icon {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-width: 72px;
      max-height: 72px;
      min-width: 72px;
      min-height: 72px;
      margin-right: 30px;
      border-radius: 50%;
      background: $white;

      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      &.has-no-bubble {
        flex: inherit;
        width: auto;
        height: auto;
        background-color: transparent;
      }

      img {
        display: block;
        max-width: 30px;
      }

      .bubble-container {

        .bubble {
          &--small,
          &--medium {
            border-width: 1px;
          }

          &--small {
            width: 89px;
            height: 89px;
          }

          &--medium {
            width: 103px;
            height: 103px;
          }
        }
      }
    }

    .item-content {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;

      .item-text {
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        color: #535756;
        margin-bottom: 20px;

        p {
          margin: 0;
        }
      }

      .item-link {
        align-self: flex-end;
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        color: $colorAccent2;

        i {
          font-size: 12px;
        }
      }

      .item-title {
        margin: 0 0 10px;
        font-size: 24px;
        line-height: 140%;
        font-weight: normal;
        color: $colorAccent2;
        text-align: left;
      }
    }


  }
}
