// --------------------------------------------------
// Popup
// --------------------------------------------------

.site-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;

  &.is-active {
    display: flex;
  }

  .popup-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    cursor: pointer;
  }

  .popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .popup-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    height: auto;
    max-height: 60vh;
    margin: 0 auto;
    padding: 60px 40px 40px;
    border-radius: 4px;
    text-align: center;
    background: $white;

    @media (max-width: 767px) {
      max-width: calc(100% - 4rem);
      height: 100%;
      max-height: calc(100% - 4rem);
    }
  }

  .popup-title {
    display: flex;
    align-items: center;
    margin: 0 0 35px;
    text-align: left;

    @media (max-width: 767px) {
      font-size: 35px;
    }

    svg {
      width: 60px;
      height: 60px;
      margin-right: 20px;

      @media (max-width: 767px) {
        width: 50px;
        height: 50px;
      }
    }
  }

  .popup-content {
    overflow-y: auto;
    flex: 1 1 100%;
    text-align: left;

    h1,
    h2,
    h3,
    h4 {
      @extend .big-title;
      margin: 0 0 15px;
      font-size: 35px;
      text-align: left;

      @media (max-width: 767px) {
        font-size: 30px;
      }
    }
  }

  .popup-btn {
    margin: 35px 0 0;
  }
}
