.block-timeline {
  background: $white;
  padding: 0;
  overflow: hidden;

  .site-max-width {
    display: flex;

    @media(max-width: 1080px) {
      flex-wrap: wrap;
    }
  }

  .left-timeline {
    padding: 100px 0 0;
    padding-left: calc((100% / 12) - 30px);

    @media(max-width: 1080px) {
      padding-top: 25px;
      padding-left: 0;
    }

    .timeline-intro {
      font-size: 36px;
      font-weight: bold;
      line-height: 27px;
      color: $colorAccent2;
      width: calc(((100% / 6)*3) + 30px);
      margin-bottom: 85px;

      @media(max-width: 1080px) {
        width: 100%;
        margin-bottom: 25px;
      }

      @media(max-width: 699px) {
        font-size: 16px;
      }
    }

    .timeline-container {
      position: relative;

      &:before,
      &:after {
        content: '';

        position: absolute;
        left: -40px;
        top: 0;

        width: 1px;
        height: 110%;

        background: $white;
      }

      &:after {
        background: $colorAccent2;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 3s linear;
      }

      &.is-active {
        &:after {
          transform: scaleY(1);
        }
      }

      span {
        display: block;
        transform: translateX(-10px);
        transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);

        &.is-active,
        &:hover {
          transform: translateX(0px);

          span {
            transform: translateX(0px);
          }
        }
      }
    }

    .timeline-item {
      position: relative;
      display: block;
      margin: 0 0 35px;
      padding-left: 7px;
      width: fit-content;
      border-radius: 22.5px;
      font-size: 16px;
      color: $white;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;

      .item-text {
        position: relative;
        margin: 0;
        padding: 15px 20px 15px 25px;
        z-index: 1;

        &::after {
          content: '';
          display: block;
          width: 100%;
          background: #fff;
          border-radius: 50%;
          padding-bottom: 100%;

          position: absolute;
          z-index: -1;
          left: -50%;
          top: 50%;
          transform: translate(-10px, -50%) scale(0);

          transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 1px;
        top: 0;
        bottom: 0;

        display: block;
        width: 10px;
        height: 10px;
        margin: auto;
        background: $white;
        border: 6px solid $white;
        z-index: 2;

        border-radius: 50%;
        transform: translateX(1px) scale(.5);
        transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
      }

      &.is-active,
      &:hover {
        color: $colorAccent2;

        &:before {
          background: $colorAccent2;
          transform: translateX(-5px) scale(1);
        }

        .item-text {
          &::after {
            transform: translate(0, -50%) scale(3.1);
          }
        }
      }
    }
  }

  .right-timeline {
    margin-left: auto;
    background-size: cover;
    background-position: center;
  }
}
