.block-logos001 {
  //background: $white;

  .logos-items {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    margin: 70px auto 0;
  }

  .logos-item {
    width: calc((100% / 3) - ((130px * 2) / 3));
    margin-right: 130px;
    margin-bottom: 60px;

    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }

    @media (max-width: 767px) {
      width: calc((100% / 2) - (70px / 2));
      margin-right: 70px;

      &:nth-of-type(3n + 3) {
        margin-right: 70px;
      }

      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
    }

    @media (max-width: 375px) {
      width: calc((100% / 2) - (40px / 2));
      margin-right: 40px;

      &:nth-of-type(3n + 3) {
        margin-right: 40px;
      }

      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
    }

    img {
      display: block;
      width: auto;
      max-width :100%;
      margin: 0 auto;
    }
  }
}
