.block-steps001 {

  .block-steps001-content {
    .medium-title {
      span   {
        color: $colorAccent1;
        border-bottom: none;
        text-transform: none;
      }
    }

    .sub-title {
      font-weight: 400;
    }
  }
  .site-btn {
    max-width: 300px;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
  }
  .steps-items {
    display: flex;
    align-items: flex-start;
    margin-top: 50px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .steps-item {
    width: 100%;
    max-width: 250px;
    z-index: 2;

    @media (max-width: 767px) {
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .item-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      margin: 0 auto 20px;
      border-radius: 50%;
      background: $colorAccent1;

      img {
        max-width: 72px;
      }
    }

    .item-title {
      font-family: $fontText;
      margin: 0 0 20px;

      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;

      color: $black;
    }

    .item-text {
      font-family: $fontText;
      margin: 0 0 20px;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;

      color: $grey2;
    }
  }

  .steps-waves {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 30px;
    margin: 25px -50px 0;

    @media (max-width: 767px) {
      display: none;
    }
  }
}
