.block-grid003 {
  position: relative;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .grid003-split-content {

    .grid-heading-section {
      text-align: left;
    }

    .services-item {
      width: calc((100% / 2) - 65px / 2);
      /*margin-right: 65px;
      margin-bottom: 40px;*/

      &:nth-child(3n) {
        margin-right: 65px;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      .item-icon.has-no-bubble {
        justify-content: left;
        margin-right: 0;
      }
    }

    &.left-split {
      width: 60%;
      position: relative;
      z-index: 2;

      @media (max-width: 1024px) {
        width: 100%;
      }

      .site-block {
        @media (max-width: 1024px) {
          padding-bottom: 0;
        }
      }

      .sub-title {

        @media (max-width: 1024px) {
          margin-left: 0;
        }
      }
    }

    &.right-split {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      background-size: cover;
      height: 100%;
      width: 100%;
      max-width: 1041px;

      @media (max-width: 1024px) {
        position: relative;
        background: none !important;
      }

      img {
        display: none;
        margin-left: auto;

        @media (max-width: 1024px) {
          display: block;
          max-height: 500px;
          max-width: 100%;
        }
      }
    }
  }
}
