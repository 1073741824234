.block-video001 {
  position: relative;
  width: 100%;
  min-height: 730px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;

  &.has-overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($black, .8);
    }

    .play-btn {
      display: block;
    }
  }

  .play-btn {
    display: none;
    position: absolute;
    top: 50%;
    lefT: 50%;
    width: 94px;
    height: 94px;
    transform: translate(-50%, -50%) scale(1);
    transition: transform .4s ease;
    pointer-events: none;

    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  video {
    width: 100%;
    pointer-events: none;
  }
}
