// --------------------------------------------------
// Content - Pagination
// --------------------------------------------------

.content-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 75px;

  @media (max-width: 1099px) {
    padding: 25px 20px;
  }

  @media (max-width: 767px) {
    margin-top: -40px;
  }

  a,
  div {
    display: flex;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid $colorAccent2;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 140%;
    color: $colorAccent2;
    text-decoration: none;
    transition: all .4s ease;

    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
    }

    &:not(.content-pagination-prev):not(.content-pagination-next) {
      justify-content: center;
      margin-right: 15px;

      &:not(.is-current) {
        &:hover {
          color: $white;
          background: $colorAccent2;
        }
      }

      @media (max-width: 767px) {
        width: 35px;
        height: 35px;
        font-size: 18px;
      }
    }

    &.content-pagination-prev,
    &.content-pagination-next {
      min-width: 150px;
      width: auto;
      padding: 0 10px;
      border-radius: 25px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;

      span {
        display: block;
        width: 100%;
        text-align: center;

        @media (max-width: 767px) {
          display: none;
          min-width: auto;
        }
      }

      &:not(.is-disabled) {
        color: $colorAccent1;
        border-color: $colorAccent1;
        font-weight: bold;

        &:hover {
          color: $white;
          background: $colorAccent1;
        }
      }

      @media (max-width: 767px) {
        min-width: auto;
        font-size: 14px;
      }
    }

    &.content-pagination-prev {
      margin-right: 20px;

      i {
        margin-right: auto;
      }
    }

    &.content-pagination-next {
      margin-left: 5px;

      i {
        margin-left: auto;
      }
    }

    &.is-current {
      color: $colorAccent1;
      border-color: $colorAccent1;
    }
  }
}
