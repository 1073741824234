// --------------------------------------------------
// Block Slider005
// --------------------------------------------------

.slider005 {
  text-align: center;
  // padding-top: 60px;
  // padding-bottom: 60px;
  background: linear-gradient(269.78deg, #F4F3F5 0.12%, #FFFFFF 99.91%);

  // @media (min-width: 992px) {
  //   padding-top: 100px;
  //   padding-bottom: 100px;
  // }

  .slider005-container {
    @extend .site-max-width;
  }

  .slider005-heading {

    @extend .big-title;
    max-width: 325px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    color: $colorAccent2;
    text-transform: uppercase;
    font-weight: 700;

    margin-bottom: 30px;


  }

  .slider005-text {
    @extend .regular-text;
    width: 100%;
    max-width: 775px;
    padding: 0 20px;
    text-align: center;
    margin: 0 auto 60px;

    @media (max-width: 1070px) {
      width: 60%;
    }

    @media (max-width: 599px) {
      width: 100%;
    }
  }

  .slider005-slider {
    text-align: center;

    .flickity-viewport {
      overflow: hidden;
      min-height: 750px;
      padding: 20px 0;

      @media all and (max-width: 1024px) {
        min-height: 800px;
      }

      @media all and (max-width: 900px) {
        min-height: 850px;
      }

      @media all and (max-width: 660px) {
        min-height: 900px;
      }

      @media all and (max-width: 560px) {
        min-height: 950px;
      }

      @media all and (max-width: 460px) {
        min-height: 1025px;
      }

      @media all and (max-width: 399px) {
        min-height: 1125px;
      }

      @media all and (max-width: 349px) {
        min-height: 1300px;
      }
    }

    .img-wrapper {
      display: block;
      width: 45%;

      @media all and (max-width: 1024px) {
        max-width: 250px;
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .card-texts-wrapper {
      width: calc(100% - 45%);
      align-self: center;
      margin-right: 45px;

      @media all and (max-width: 1024px) {
        margin-right: 0;
        width: 100%;
      }
      .value-title {
        @extend .title4;
        font-size: 16px;
        width: 100%;
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: normal;
        font-size: 45px;
        line-height: 140%;
        color: $colorAccent1;
        text-align: left;
      }

      .value-desc {
        @extend .regular-text;
        width: 100%;
        margin: 0;
        font-weight: 300;
        font-size: 24px;
        line-height: 140%;
        color: $colorAccent2;
        text-align: left;

        @media all and (max-width: 1024px) {
          font-size: 21px;
          // line-height: 16px;
          max-width: 100%;
        }
      }
    }

    .value-card {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
      height: 100%;
      padding-bottom: 40px;

      @media all and (max-width: 1024px) {
        flex-direction: column;
        padding-bottom: 80px;
      }

      &.is-selected {
        position: relative;
      }

      // Options
      &.grey {
        background-color: $colorAccent2;
      }
      &.dark {
        background-color: $colorAccent2;

        .value-title {
          color: white;
        }
      }

      &.left {
        .value-title, .value-desc {
          text-align: left;
        }
      }
      &.right {
        .value-title, .value-desc {
          text-align: right;
        }
      }
    }
  }

  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
    transition: all ease-out .2s;
    cursor: pointer;
    z-index: 1;
    background-color: transparent;

    &:hover:not(:disabled) {
      transform: translateY(-50%) scale(1.2);
      opacity: 1;
      box-shadow: 0 0 20px 0 rgba($colorAccent2, .5);
    }

    &.previous {
      left: -50px;
    }
    &.next {
      right: -50px;
    }

    &:disabled {
      opacity: .1;
    }

    @media(max-width: 1349px) {
      &.previous {
        left: -50px;
      }
      &.next {
        right: -50px;
      }
    }
  }

  .flickity-page-dots {
    left: 45px;
    bottom: 0;
    width: auto;

    .dot {
      background-color: #ACB5B6;
      transition: background-color ease-out .2s;

      &.is-selected {
        background-color: transparent;
        border: 1px solid #ACB5B6;
      }
    }
  }

  // Options
  &.grey {
    background-color: $colorAccent2;
  }
  &.dark {
    background-color: $colorAccent2;

    .slider005-heading {
      color: white;
    }
  }
}
