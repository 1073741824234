.careers-section {
  margin: 0 auto;
  width: 100%;
  max-width: 920px;
  padding: 70px 20px;

  @media (max-width: 900px) {
    padding: 40px 20px;
  }

  .big-title {
    margin: 0 0 20px;
    text-align: center;
  }

  .careers-intro {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 40px;
    text-align: center;
  }

  .careers-listing {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #E6E6E6;
  }

  .careers-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;
    padding: 30px 20px;

    @media (max-width: 900px) {
      padding: 20px;
      flex-wrap: wrap;
    }

    &:nth-child(even) {
      background-color: #F0F0F0;
    }
  }

  .careers-link {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: $colorAccent1;
    border: 2px solid $colorAccent1;
    font-weight: 700;
    transition: all ease-out .2s;

    @media (max-width: 900px) {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
    }

    &:hover {
      background-color: $colorAccent1;
      color: white;
    }
  }

  .careers-infos {
    width: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1180px) {
      width: 400px;
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      width: 250px;
    }

    @media (max-width: 900px) {
      width: auto;
    }

    & > .careers-type, & > .careers-location {
      width: 50%;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: .2px;
      color: #6D6D6D;
      font-weight: 400;
      margin: 0;

      @media (max-width: 1023px) {
        margin: 5px 0;
        width: 100%;
      }
    }
  }

  .careers-name {
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 900px) {
      width: 100%;
      margin: 0 0 20px;
    }
  }
}




.swal2-shown {

  .swal2-content {
    font-size: 20px;
  }

  .swal2-styled.swal2-confirm {
    font-size: 16px;
  }
}

.contact-form-wrapper {
  background-color: #F8F8F8;

  .contact-form-heading {
    margin-top: 0;
    margin-bottom: 100px;
    width: 100%;
    text-align: center;
    color: #3F4040;
  }

  .contact-form {
    width: 100%;
    max-width: 900px;
    padding: 70px 20px;
    margin: 0 auto;

    @media(max-width: 700px) {
      padding: 40px 20px;
    }

    .big-title {
      margin-bottom: 80px;

      @media(max-width: 700px) {
        margin-bottom: 40px;
      }
    }

    .site-btn {
      display: block;
      max-width: 135px;
      margin: 0 auto;
    }
  }
}


.career-content-wrapper {
  .career-intro {
    margin: 30px 0;

    p {
      font-family: $fontText;
      font-weight: 300;
      font-size: 24px;
      line-height: 140%;
      color: $grey2;
    }
  }

  .blog-intro {
    text-align: left;
    align-items: flex-start;
    margin-bottom: 0;
    padding-bottom: 30px;
    color: $colorAccent2;

    &:after {
      display: none;
    }
  }

  .blog-content {
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 140%;
    }
  }

  .jobs-listing {
    .jobs-item {
      .item-content {
        border-radius: 12px;
      }
    }
  }
}

.jobs-form-wrapper {
  margin-top: 68px;
  padding-top: 68px;
  border-top: 1px solid #e4e4e4;

  h2 {
    margin-bottom: 44px;
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    color: $colorAccent1;
  }

  form {

    .file-inputs {
      .half-inputs {
        border: 1px solid lighten($colorAccent2, 34%);
        border-radius: 5px;
        padding: 10px 12px 8px;
        opacity: 0;

        max-width: 340px;
        cursor: pointer;
      }
    }

    .delegate-wrapper {
      position: relative;

      .delegate-input {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        width: 100%;

        border: 1px solid lighten($colorAccent2, 34%);
        border-radius: 5px;
        padding: 0 0 0 12px;

        pointer-events: none;
        cursor: pointer;

        .delegate-text {
          margin-top: 6px;
          font-size: 14px;
          line-height: 18px;
          color: $grey;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
        }

        .cover-button {
          height: 100%;
          background: $colorAccent2;
          margin-left: auto;
          color: $white;
          padding: 8px 12px 8px;
        }
      }
    }
  }
}
