//.services-content{
//  .blog-cta {
//    margin-top: 50px;
//
//    .site-btn {
//      margin-top: 20px;
//    }
//  }
//}

//.block-services {
//  background: $white url('/img/site/globe-plakett.png') no-repeat left center / auto;
//
//  &.services-single {
//    border-top: 1px solid rgba($white, .5);
//    border-bottom: 1px solid rgba($white, .5);
//    background: $white;
//
//    @media(max-width: 870px) {
//      border-top: none;
//      border-bottom: none;
//    }
//
//    .services-wrapper {
//      padding-top: 0;
//    }
//
//    .services-text {
//      padding-top: 50px;
//
//      @media(max-width: 870px) {
//        padding-bottom: 50px;
//      }
//
//      @media(max-width: 499px) {
//        padding-top: 0;
//      }
//    }
//
//    .services-listing {
//      border-top: none;
//      border-bottom: none;
//      border-left: 1px solid rgba($white, .5);
//      border-right: 1px solid rgba($white, .5);
//
//      @media(max-width: 870px) {
//        border-top: 1px solid rgba($white, .5);
//        border-bottom: 1px solid rgba($white, .5);
//        border-left: 1px solid rgba($white, .5);
//        border-right: 1px solid rgba($white, .5);
//        margin-bottom: 50px;
//      }
//    }
//  }
//
//  .services-wrapper {
//    display: flex;
//    flex-wrap: wrap;
//    padding-top: 150px;
//
//    @media(max-width: 768px) {
//      padding-top: 50px;
//    }
//  }
//
//  .services-text {
//    font-family: $fontText;
//    font-style: normal;
//    font-weight: normal;
//    font-size: 26px;
//    line-height: 169%;
//    color: $colorAccent2;
//
//    @media(max-width: 768px) {
//      font-size: 19px;
//      margin-bottom: 20px;
//    }
//  }
//}

.careers-listing-title {
  margin-top: 0;
  margin-bottom: 50px;
  font-family: $fontText;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 169%;
  letter-spacing: 0;
  color: $colorAccent2;
}
//
//.services-listing {
//  display: flex;
//  flex-direction: column;
//  border: 1px solid rgba($white, .5);
//
//  &.careers-listing {
//    width: 100%;
//  }
//}
//
//.services-item {
//  position: relative;
//  display: inline-block;
//  padding: 30px 50px;
//  border-bottom: 1px solid rgba($white, .5);
//  font-family: $fontText;
//  font-style: normal;
//  font-weight: 500;
//  font-size: 21px;
//  line-height: 170%;
//  color: $colorAccent2;
//  background: $white;
//  transition: box-shadow linear calc(.6s / 3);
//
//  @media(max-width: 768px) {
//    padding: 20px;
//    font-size: 16px;
//  }
//
//  &:nth-child(even) {
//    background: #FBFDFF;
//  }
//
//  &:last-of-type {
//    border-bottom: none;
//  }
//
//  &::before {
//    content: '';
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 5px;
//    height: 100%;
//    background-color: $colorAccent1;
//    transform: scaleX(0);
//    transform-origin: center left;
//    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
//  }
//
//  &::after {
//    content: '\E82B';
//    position: absolute;
//    top: 50%;
//    right: 40px;
//    font-family: 'fontello';
//    color: $white;
//    opacity: 0;
//    transform: translateY(-50%);
//    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
//
//    @media (max-width: 767px) {
//      content: none;
//    }
//  }
//
//  span {
//    display: inline-block;
//    transition: transform .6s cubic-bezier(0.165, 0.84, 0.44, 1);
//  }
//
//  .services-item-career {
//    margin-left: 10px;
//    padding-left: 10px;
//    padding-right: 10px;
//    border-radius: 40px;
//    font-family: $fontText;
//    font-style: normal;
//    font-weight: 500;
//    font-size: 16px;
//    line-height: 170%;
//    letter-spacing: 0;
//    color: $white;
//  }
//
//  &:hover {
//    box-shadow: 0px 0px 70px rgba(0, 0, 0, .1);
//    z-index: 1;
//
//    span {
//      transform: translateX(5px);
//    }
//
//    &::before {
//      transform: scaleX(1);
//    }
//
//    &::after {
//      right: 35px;
//      opacity: 1;
//    }
//  }
//}
