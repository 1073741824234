.page-company {
  .site-block {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .block-team001-content {
    .medium-title {
      max-width: 500px;

      font-family: $fontTitle;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0px;
      text-align: center;

    }
  }

  .block-news001 {
    background-color: $light;
  }

  .home-services-subjects {
    .subjects-featured {
      &.items-2 {
        justify-content: space-evenly;
      }
    }
  }
}
