// --------------------------------------------------
// Hero
// --------------------------------------------------
.motion-intelligence,
.showcase,
.contact {
  .site-hero {
    padding-bottom: 165px;

    @media only screen and (max-width: 1349px) {
      padding-bottom: 75px;
    }

    @media only screen and (max-width: 1099px) {
      padding-bottom: 55px;
    }

    @media only screen and (max-width: 767px) {
      padding-bottom: 45px;
    }
  }
}

.site-hero {
  position: relative;
  height: auto;
  padding-top: 165px;
  padding-bottom: 355px;
  background-image: url('https://images.unsplash.com/photo-1556742504-16b083241fab?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  min-height: 694px;
  max-height: 100vh;

  .contact & {
    padding-bottom: 155px;
  }

  @media only screen and (max-width: 1349px) {
    padding-top: 165px;
    padding-bottom: 75px;
    background-position: center center;
  }

  @media only screen and (max-width: 1099px) {
    padding-top: 135px;
    padding-bottom: 55px;
    min-height: 100%;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 105px;
    padding-bottom: 45px;
    background-position: 60% center;
  }

  &.has-overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 5;
        background: rgba($black, .04);
    }

    &.dark:before {
      background: rgba($colorAccent2, .9);
    }

    &.light:before {
      background: rgba($white, .9);
    }

    &.accent1:before {
      background: rgba($colorAccent1, .9);
    }

    &.accent2:before {
      background: rgba($colorAccent2, .9);
    }

    &.is-small {
      min-height: 495px;
      padding-bottom: 155px;

      @media only screen and (max-width: 1349px) {
        padding-top: 165px;
        padding-bottom: 75px;
      }

      @media only screen and (max-width: 1099px) {
        padding-top: 135px;
        padding-bottom: 55px;
      }

      @media only screen and (max-width: 767px) {
        min-height: 395px;
        padding-top: 105px;
        padding-bottom: 45px;
      }

      @media only screen and (max-width: 499px) {
        min-height: 300px;
        padding-top: 105px;
        padding-bottom: 45px;
      }
    }
  }

  /*&.has-line {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 2px;
      height: 150px;
      transform-origin: bottom center;
      transform: translateX(-50%) scaleY(0);
      animation: grow 1s forwards .6s;
      will-change: transform;
      background: $colorAccent1;
    }

    @keyframes grow {
      0% {
        transform: translateX(-50%) scaleY(0);
      }

      50% {
        transform: translateX(-50%) scaleY(0.5);
      }

      100% {
        transform: translateX(-50%) scaleY(1);
      }
    }
  }*/

  .hero-slider {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .flickity-viewport {
      width: 100%;
      height: 100%;
    }

    .hero-slide {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  .hero-video-wrapper {
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    background-size: cover;
    background-color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: none;

    .hero-video {
      display: block;
      margin: auto;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: visible;
      opacity: 1;
      width: 1682px;
      height: auto;
    }
  }

  //.site-btn {
  //  margin-top: 55px;
  //}


  .hero-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.align-left {
      align-items: flex-start;
    }

    &.align-center {
      align-items: center;
      text-align: center;
    }

    &.align-right {
      align-items: flex-end;
      text-align: right;
    }

    .btn-wrapper {
      @media (max-width: 499px) {
        display: flex;
        flex-direction: column;
      }

      .hero-btn {
        display: inline-block;

        &:first-of-type {
          margin-right: 20px;

          @media (max-width: 499px) {
            margin-right: 0;
            margin-bottom: 20px;
          }
        }
      }
    }

    .options-wrapper {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      margin-top: 70px;

      @media(max-width: 999px) {
        flex-direction: column;
      }

      @media(max-width: 767px) {
        margin-top: 0;
      }

      .option-content {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        margin-right: 90px;
        margin-bottom: 20px;

        @media(max-width: 767px) {
          margin-right: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        img {
          display: block;
          max-width: 72px;
          margin-right: 20px;


        }

        p {

          font-weight: normal;
          font-size: 24px;
          line-height: 140%;
          color: $colorAccent1;

          @media(max-width: 767px) {
            font-size: 21px;
          }
        }
      }
    }

    .hero-heading {
      @extend .big-title;
      color: $black;
      font-weight: 900;

      margin-top: 0;
      //margin-bottom: 30px;
      margin-bottom: 22px;
      font-size: 78px;

      @media only screen and (max-width: 1349px) {
        font-size: 55px;
      }

      @media only screen and (max-width: 1099px) {
        font-size: 45px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 35px;
      }
    }

    .hero-subheading {
      margin-top: 0;

      font-family: $fontText;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 41px;
      letter-spacing: 0px;
      text-align: center;

      color: $grey;
      max-width: 643px;
      margin-bottom: 20px;


      @media only screen and (max-width: 1349px) {
        font-size: 30px;
      }

      @media only screen and (max-width: 1099px) {
        font-size: 25px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    .hero-text {
      max-width: 860px;
      margin-top: 0;
      margin-bottom: 45px;
      font-family: $fontText;
      font-size: 30px;
      line-height: 140%;
      color: rgba($colorAccent2, .8);

      @media only screen and (max-width: 1349px) {
        font-size: 25px;
      }

      @media only screen and (max-width: 1099px) {
        font-size: 20px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .site-link-btn {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .bubble-container {
    position: absolute;

    // @media (max-width: 767px) {
    //   display: none;
    // }

    &:nth-child(1) {
      left: 16%;
      bottom: 20%;

      @media (max-width: 1100px) {
        transform: scale(.5);
        left: 18%;
        bottom: 10%;
      }

      @media (max-width: 900px) {
        left: 18%;
        bottom: 20%;
      }

      @media (max-width: 650px) {
        display: none;
        left: 10%;
      }
    }

    &:nth-of-type(2) {
      display: none;
      left: 33%;
      bottom: 45%;
      transform: scale(.5);

      @media (max-width: 1480px) {
        bottom: 26%;
      }

      @media (max-width: 1100px) {
        transform: scale(.4);
        left: 32%;
        bottom: 20%;
      }

      @media (max-width: 900px) {
        transform: scale(.2);
        left: 32%;
        bottom: 25%;
      }

      @media (max-width: 650px) {
        left: 29%;
      }
      .bubble {
        &--full { animation-delay: 1.5s }
        &--small { animation-delay: 1.7s }
        &--medium { animation-delay: 1.7s }
        &--large { animation-delay: 1.9s }
        &--larger { animation-delay: 2.1s }
      }
    }

    &:nth-of-type(3) {
      left: 42%;
      bottom: 40%;
      transform: scale(.5);

      @media (max-width: 1480px) {
        bottom: 28%;
      }

      @media (max-width: 1100px) {
        transform: scale(.3);
        left: 42%;
        bottom: 18%;
      }

      @media (max-width: 900px) {
        left: 17%;
        bottom: 28%;
      }

      .bubble {
        &--full { animation-delay: 1s }
        &--small { animation-delay: 1.2s }
        &--medium { animation-delay: 1.2s }
        &--large { animation-delay: 1.4s }
        &--larger { animation-delay: 1.6s }
      }
    }
    &:nth-of-type(4) {
      right: 30%;
      bottom: 35%;
      transform: scale(.7);

      @media (max-width: 1480px) {
        bottom: 20%;
      }

      @media (max-width: 1100px) {
        transform: scale(.5);
        right: 28%;
        bottom: 12%;
      }

      @media (max-width: 900px) {
        transform: scale(.3);
        right: 15%;
        bottom: 21%;
      }

      .bubble {
        &--full { animation-delay: 2s }
        &--small { animation-delay: 2.2s }
        &--medium { animation-delay: 2.2s }
        &--large { animation-delay: 2.4s }
        &--larger { animation-delay: 2.6s }
      }
    }

    &:nth-of-type(5) {

      right: 16%;
      bottom: 15%;
      transform: scale(.4);

      @media (max-width: 1480px) {
        right: 16%;
        bottom: 3%;
      }

      @media (max-width: 1350px) {
        bottom: 7%;
      }

      @media (max-width: 1200px) {
        right: 13%;
      }

      @media (min-width: 951px) and (max-width: 1100px) {
        display: none;
      }

      @media (max-width: 950px) {
        transform: scale(.2);
        right: 16%;
        bottom: 2%;
      }

      @media (max-width: 650px) {
        right: 16%;
        bottom: 5%;
      }

      @media (max-width: 600px) {
        display: none;
        right: 10%;
        bottom: 7%;
      }

      .bubble {
        &--full { animation-delay: 1.5s }
        &--small { animation-delay: 1.7s }
        &--medium { animation-delay: 1.7s }
        &--large { animation-delay: 1.9s }
        &--larger { animation-delay: 2.1s }
      }
    }
  }

  .mouse {
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    z-index: 10;

    @media (max-width: 1099px) {
      display: none;
    }

    .mouse-icon {
      width: 20px;
      height: 35px;
      border: 2px solid $colorAccent1;
      border-radius: 15px;
      cursor: pointer;
      position: relative;
      text-align: center;
    }

    .mouse-wheel {
      height: 6px;
      margin: 2px auto 0;
      display: block;
      width: 3px;
      background-color: $colorAccent1;
      border-radius: 50%;
      -webkit-animation: 1.6s ease infinite wheel-up-down;
      -moz-animation: 1.6s ease infinite wheel-up-down;
      animation: 1.6s ease infinite wheel-up-down;
    }

    @-webkit-keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      100% {
        margin-top: 20px;
        opacity: 0;
      }
    }
    @-moz-keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      100% {
        margin-top: 20px;
        opacity: 0;
      }
    }
    @keyframes wheel-up-down {
      0% {
        margin-top: 2px;
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      100% {
        margin-top: 20px;
        opacity: 0;
      }
    }
  }
}
